import React, { useEffect, useState } from 'react';
import { Box, Button, IconButton, Tooltip, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useDispatch, useSelector } from 'react-redux';
import { fetchVehicles, fetchDivisions, fetchSubdivisions, fetchDepartments,disableVehicle } from '../../redux/actions/recordAction';
import AddVehicleModal from './AddVehicleModal';
import AssignVehicleModal from './AssignVehicleModal';
import format from 'date-fns/format';
import EditIcon from '@mui/icons-material/Edit';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import HistoryIcon from '@mui/icons-material/History';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import { useNavigate } from 'react-router-dom';

const Contacts = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch();
    const vehicles = useSelector((state) => state.record.vehicles) || [];
    const error = useSelector((state) => state.record.error);
    const divisions = useSelector((state) => state.record.divisions) || [];
    const subdivisions = useSelector((state) => state.record.subdivisions) || [];
    const userDivision = useSelector((state) => state.login.user?.division) || [];
    const departments = useSelector((state) => state.record.departments) || [];
    const [openAdd, setOpenAdd] = useState(false);
    const [openAssign, setOpenAssign] = useState(false);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const navigate = useNavigate();
    
    const isDarkMode = theme.palette.mode === 'dark';
    const division = "None";

    useEffect(() => {
        dispatch(fetchVehicles());
        dispatch(fetchDivisions());
        dispatch(fetchSubdivisions());
        dispatch(fetchDepartments());
        // dispatch(disableVehicle());
    }, [dispatch]);

    const handleAssignVehicle = (vehicle) => {
        setSelectedVehicle(vehicle);
        setOpenAssign(true);
    };

    const handleEditVehicle = (vehicle) => {
        setSelectedVehicle(vehicle);
        setIsEditing(true);
        setOpenAdd(true);
    };

    const handleCloseAssignModal = () => {
        setOpenAssign(false);
        setSelectedVehicle(null);
    };

    const handleCloseAddModal = () => {
        setOpenAdd(false);
        setSelectedVehicle(null);
        setIsEditing(false);
        dispatch(fetchVehicles());
    };

    const handleDisableVehicle = (vehicle) => {
        const confirmDisable = window.confirm(`Are you sure you want to disable the vehicle with IMEI ${vehicle.imei}?`);
        if (confirmDisable) {
            dispatch(disableVehicle(vehicle.id))
                .then(() => {
                    alert(`Vehicle with IMEI ${vehicle.imei} has been disabled.`);
                    dispatch(fetchVehicles()); // Refresh the vehicle list
                })
                .catch((error) => {
                    alert(`Failed to disable the vehicle: ${error.message}`);
                });
        }
    };
    const filteredVehicles = vehicles.filter((vehicle) => {

        if (userDivision === null || userDivision === division) {
            return vehicle.status !== 'disabled';
        } else {
            return vehicle.division_name === userDivision && vehicle.status !== 'disabled';
        }
        });

    const processedVehicles = filteredVehicles.map((vehicle, index) => ({
    ...vehicle,
    No: index + 1,
    id: vehicle.id,
    purchase_date: vehicle.purchase_date ? format(new Date(vehicle.purchase_date), 'yyyy-MM-dd') : '' // Handle null or undefined dates
    }));
    // const processedVehicles = vehicles?.map((vehicle, index) => ({
    //     ...vehicle,
    //     No: index + 1,
    //     id: vehicle.id,
    //     purchase_date: vehicle.purchase_date ? format(new Date(vehicle.purchase_date), 'yyyy-MM-dd') : '' // Handle null or undefined dates
    // }));

    const columns = [
        { field: "No", headerName: "No", flex: 0.5 },
        { field: "imei", headerName: "IMEI", flex: 1 },
        { field: "tracker_phone", headerName: "Phone Number", flex: 1 },
        { field: "civilian_plate", headerName: "Civilian Registration Number", flex: 1 },
        { field: "gk_plate", headerName: "GK Registration Number", flex: 1 },
        { field: "chasis_no", headerName: "Chasis Number", flex: 1 },
        { field: "engine_no", headerName: "Engine Number", flex: 1 },
        {field: "make_name", headerName: "Make", flex: 1 },
        {field: "model_no", headerName: "Model", flex: 1 },
        { field: "purchase_date", headerName: "Date of Purchase", flex: 1 },
        {
        field: "id",
        headerName: "ID",
        hide: true // Hide the ID column from the display
        },
        {
            field: "actions",
            headerName: "Actions",
            flex: 2,
            renderCell: (params) => (
                <div>
                    <Tooltip title="Edit Vehicle">
                        <IconButton sx={{color: isDarkMode ? 'white' : 'black'}} onClick={() => handleEditVehicle(params.row)}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Disable Vehicle">
                <IconButton
                    sx={{ color: isDarkMode ? 'white' : 'black' }}
                    onClick={() => handleDisableVehicle(params.row)}
                >
                    <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>🚫</span>
                </IconButton>
            </Tooltip>

                    <Tooltip title="Assign Vehicle">
                        <IconButton sx={{color: isDarkMode ? 'white' : 'black'}} onClick={() => handleAssignVehicle(params.row)}>
                            <DirectionsCarIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="View History">
                        <IconButton sx={{color: isDarkMode ? 'white' : 'black'}} onClick={() => navigate(`/vehicle-history/${params.row.imei}`)}>
                            <HistoryIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Fuel Consumption">
                        <IconButton sx={{color: isDarkMode ? 'white' : 'black'}}
                        // onClick={() => navigate(`/vehicle-history/${params.row.imei}`)}
                        >
                            <LocalGasStationIcon />
                        </IconButton>
                    </Tooltip>

                </div>
            ),
        }
    ];

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Vehicles" subtitle="List of Vehicles" />
                <Button
                    sx={{
                        backgroundColor: colors.blueAccent[700],
                        color: colors.grey[100],
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                        mb: "20px"
                    }}
                    onClick={() => setOpenAdd(true)}
                >
                    Add Vehicle
                </Button>
                <AddVehicleModal
                    open={openAdd}
                    handleClose={handleCloseAddModal}
                    vehicle={selectedVehicle}
                    isEditing={isEditing}
                />
            </Box>
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                    },
                }}
            >
                <DataGrid
                    rows={processedVehicles}
                    columns={columns}
                    components={{ Toolbar: GridToolbar }}
                />
            </Box>
            {selectedVehicle && (
                <AssignVehicleModal
                    open={openAssign}
                    handleClose={handleCloseAssignModal}
                    vehicle={selectedVehicle}
                    divisions={divisions}
                    subdivisions={subdivisions}
                    departments={departments}
                />
            )}
        </Box>
    );
};

export default Contacts;
