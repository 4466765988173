import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Modal, Typography, useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';
import { editDivision } from '../../redux/actions/recordAction';
import { tokens } from "../../theme";

const EditDivisionModal = ({ open, handleClose, division }) => {
  const [divName, setDivName] = useState('');
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    if (division) {
      setDivName(division.div_name);
    }
  }, [division]);

  const handleEditDivision = () => {
    dispatch(editDivision(division.id, divName));
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box 
        display="flex" 
        flexDirection="column" 
        justifyContent="center" 
        alignItems="center" 
        p={3} 
        bgcolor={theme.palette.background.paper}
        color={theme.palette.text.primary}
        borderRadius={2}
        boxShadow={3}
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '300px',
        }}
      >
        <Typography variant="h6" mb={2}>Edit Division</Typography>
        <TextField 
          label="Division Name" 
          value={divName} 
          onChange={(e) => setDivName(e.target.value)} 
          fullWidth 
          mb={2} 
          InputLabelProps={{
            style: { color: theme.palette.text.primary }
          }}
          InputProps={{
            style: { color: theme.palette.text.primary }
          }}
        />
        <Button 
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
          }} 
          onClick={handleEditDivision}
        >
          Edit Division
        </Button>
      </Box>
    </Modal>
  );
};

export default EditDivisionModal;
