import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, useTheme, IconButton } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useDispatch, useSelector } from 'react-redux';
import { fetchDivisions, addDivision, editDivision, deleteDivision } from '../../redux/actions/recordAction';
import Header from "../../components/Header";
import { tokens } from "../../theme";
import AddDivisionModal from './AddDivisionModal.jsx';
import EditDivisionModal from './EditDivisionModal.jsx';

const Divisions = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const divisions = useSelector((state) => state.record.divisions) || [];
  const error = useSelector((state) => state.record.error);
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [currentDivision, setCurrentDivision] = useState(null);

  useEffect(() => {
    dispatch(fetchDivisions());
  }, [dispatch]);

  const handleCloseAddModal = () => {
    setOpenAdd(false);
    dispatch(fetchDivisions());
  };

  const handleCloseEditModal = () => {
    setOpenEdit(false);
    dispatch(fetchDivisions());
  };

  const handleEdit = (division) => {
    setCurrentDivision(division);
    setOpenEdit(true);
  };


const columns = [
  {
    field: "index",
    headerName: "No.",
    flex: 0.5,
    renderCell: (params) => {
      return <Typography>{params.row.index}</Typography>; // Display the index number
    },
  },
  {
    field: "div_name",
    headerName: "Division Name",
    flex: 1
  },
  {
    field: "id",
    headerName: "ID",
    flex: 0.5,
    hide: true, // Hide the _id column
  },
  {
    field: "actions",
    headerName: "Actions",
    flex: 1,
    renderCell: (params) => (
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleEdit(params.row)}
          style={{ marginRight: 8 }}
        >
          Edit
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => dispatch(deleteDivision(params.row.id))}
        >
          Delete
        </Button>
      </div>
    ),
  }
];

const processedDivisions = divisions.map((division, index) => ({
  ...division,
  index: index + 1,
}));

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DIVISIONS" subtitle="Managing the Divisions" />
        <Button sx={{
          backgroundColor: colors.blueAccent[700],
          color: colors.grey[100],
          fontSize: "14px",
          fontWeight: "bold",
          padding: "10px 20px",
        }} onClick={() => setOpenAdd(true)}>
          Add Division
        </Button>
        <AddDivisionModal open={openAdd} handleClose={handleCloseAddModal} />
        {currentDivision && (
          <EditDivisionModal
            open={openEdit}
            handleClose={handleCloseEditModal}
            division={currentDivision}
          />
        )}
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={processedDivisions}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default Divisions;
